import axios from "axios";

export async function post(endpoint, data) {
  let frontUrl = "https://northtreeapi.trakr.it";
  if (process.env.NODE_ENV === "development") {
    frontUrl = "http://localhost:4000";
  }
  const url = `${frontUrl}/v1/${endpoint}`;
  let returndata = {};
  await axios
    .post(url, data)
    .then(async (response) => {
      returndata = response;
    })
    .catch((error) => {
      return error;
    });
  return returndata;
}
